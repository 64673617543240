import React, {useContext} from 'react';
import * as PropTypes from "prop-types";
import clsx from "clsx";
import {makeStyles} from "@mui/styles";
import {
    Card, CardActions, CardContent, CardHeader,
    CardMedia, Chip, Collapse, IconButton, Tooltip
} from "@mui/material";
import {amber, green, red} from "@mui/material/colors";
import {
    Web as WebIcon,
    Check as CheckIcon,
    Close as CloseIcon,
    Build as BuildIcon,
    ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import Skeleton from "@mui/material/Skeleton";

import GitLab from "../Assets/Icon/GitLabIcon";
import LanguageContext from "../LanguageContext";
import {Alert, AlertTitle} from "@mui/material";

StateAlert.propTypes = {
    isDown: PropTypes.bool,
    isMaintained: PropTypes.bool,
    downStartDate: PropTypes.instanceOf(Date),
    downEndDate: PropTypes.instanceOf(Date),
    loading: PropTypes.bool.isRequired
};

StateChip.propTypes = {
    isDown: PropTypes.bool,
    isMaintained: PropTypes.bool,
    downStartDate: PropTypes.instanceOf(Date),
    downEndDate: PropTypes.instanceOf(Date),
    loading: PropTypes.bool.isRequired
};

StatusCard.propTypes = {
    name: PropTypes.string,
    isDown: PropTypes.bool,
    isMaintained: PropTypes.bool,
    downPeriod: PropTypes.exact({
        start: PropTypes.string,
        end: PropTypes.string,
        reason: PropTypes.shape({
            en: PropTypes.string,
            fr: PropTypes.string,
        })
    }),
    gitlab: PropTypes.string,
    link: PropTypes.string,
    logo: PropTypes.string,
    loading: PropTypes.bool.isRequired
};

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 500
    },
    cardMedia: {
        maxHeight: "100%",
        height: "10vh",
        width: "auto",
        margin: "auto"
    },
    chip: {
        color: "white",
        margin: theme.spacing(0.5)
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: red[600],
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        transform: "scale(1.4)",
    },
    iconColored: {
        color: "white",
    },
    skeleton: {
        display: "inline-flex",
        margin: theme.spacing(0.5)
    },
    skeletonChip: {
        borderRadius: 16
    },
    skeletonAlert: {
        width: "80%"
    },
    statusAlert: {
        minHeight: "min(15vh, 80px)",
        textAlign: "center",
        justifyContent: "center",
        verticalAlign: "center"
    },
    underline: {
        textDecoration: "underline"
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto'/*,
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),*/
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

function StateAlert({downStartDate, downEndDate, isDown, isMaintained, loading}) {
    const classes = useStyles();
    const local = useContext(LanguageContext).Component.StatusCard;

    let currentDate = new Date();
    let textToDisplay = local.StateAlert.up;
    let alertSeverity = "success";

    if(isDown || isMaintained){
        textToDisplay = local.StateAlert.down;
        alertSeverity = "error";
    }

    if (downStartDate && downEndDate){
        if(isMaintained || (currentDate >= downStartDate && currentDate <= downEndDate)){
            alertSeverity = "warning";
            textToDisplay = textToDisplay.concat(local.StateAlert.isMaintaining);
        } else if (currentDate < downStartDate){
            textToDisplay = textToDisplay.concat('. ', local.StateAlert.hasPeriod);
        }
    }

    return (loading ? <Skeleton className={clsx(classes.skeleton, classes.skeletonAlert, classes.statusAlert)} variant="rectangular"/> : <Alert severity={alertSeverity} className={classes.statusAlert}>{textToDisplay}.</Alert>);
}

function StateChip({downStartDate, downEndDate, isDown, isMaintained, loading}) {
    const classes = useStyles();
    const local = useContext(LanguageContext).Component.StatusCard;
    const stateEnum = {
        DOWN: {
            color: classes.error,
            label: local.StateChip.down,
            icon: <CloseIcon className={classes.iconColored}/>
        },
        MAINTAINING: {
            color: classes.warning,
            label: local.StateChip.maintaining,
            icon: <BuildIcon className={classes.iconColored}/>
        },
        UP: {
            color: classes.success,
            label: local.StateChip.up,
            icon: <CheckIcon className={classes.iconColored}/>
        }
    };

    let currentDate = new Date();
    let state;

    if(isMaintained || (currentDate >= downStartDate && currentDate <= downEndDate)){
        state = stateEnum.MAINTAINING;
    } else {
        state = (isDown) ? stateEnum.DOWN : stateEnum.UP;
    }

    return (loading ? <Skeleton className={clsx(classes.skeleton, classes.skeletonChip)} variant="rectangular" width={135} height={32}/> : <Chip className={clsx(state.color, classes.chip)} label={state.label} icon={state.icon}/>);
}

function StatusCard({name, isDown, isMaintained, downPeriod, logo = `${process.env.PUBLIC_URL}/logo.png`, gitlab, link, loading = true}) {
    const classes = useStyles();
    const local = useContext(LanguageContext);
    const [expanded, setExpanded] = React.useState(false);

    const reason = (downPeriod) ? downPeriod.reason[local.Lang] : null;
    // Cast str to Date
    const downStartDate = (downPeriod && downPeriod.start) ? new Date(downPeriod.start) : null;
    const downEndDate = (downPeriod && downPeriod.end) ? new Date(downPeriod.end) : null;
    // We display only if there is a maintaining period in the present or the future
    const canDisplayMore = (downStartDate && downEndDate && reason) && (new Date() <= downEndDate);

    /**
     * Anonymous function to get a proper formatted string from a JSON Date
     * @param   {Date}    date    The date to transform
     * @return  {string}          The formatted date string
     */
    const transformDateToStr = (date) => date.toLocaleString(local.LangFormat, { timeZone: 'Europe/Paris' }).replace(',',' -');

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        (<Card className={classes.root}>
            {loading ?
                <>
                    <Skeleton className={classes.skeleton} variant="text"><CardHeader title={"Titre d'app"}/></Skeleton>
                    <br/>
                    <Skeleton className={classes.skeleton} variant="circular"><CardMedia className={classes.cardMedia} component={"img"} alt={`Logo SIA`} src={logo}/></Skeleton>
                </>
                :
                <>
                    <CardHeader title={name}/>
                    <CardMedia className={classes.cardMedia} component={"img"} alt={`Logo ${name}`} src={logo}/>
                </>
            }
            <CardContent>
                <StateChip loading={loading} isDown={isDown} isMaintained={isMaintained} downEndDate={downEndDate} downStartDate={downStartDate}/>
                <StateAlert loading={loading} isDown={isDown} isMaintained={isMaintained} downEndDate={downEndDate} downStartDate={downStartDate}/>
            </CardContent>
            {!loading &&
                <>
                    <CardActions disableSpacing>
                        <Tooltip title={local.Component.StatusCard.Tooltip.gitlab}>
                            <IconButton
                                component={"a"}
                                href={gitlab}
                                rel="noopener noreferrer"
                                target="_blank"
                                aria-label={local.Component.StatusCard.IconButton.gitlab}
                                size="large">
                                <GitLab className={classes.icon}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={local.Component.StatusCard.Tooltip.link}>
                            <IconButton
                                component={"a"}
                                href={link}
                                rel="noopener noreferrer"
                                target="_blank"
                                aria-label={local.Component.StatusCard.IconButton.link}
                                size="large">
                                <WebIcon/>
                            </IconButton>
                        </Tooltip>
                        {canDisplayMore &&
                        <Tooltip title={(expanded) ? local.Component.StatusCard.Tooltip.detailExpanded : local.Component.StatusCard.Tooltip.detailCollapsed}>
                            <IconButton
                                className={clsx(classes.expand, {[classes.expandOpen]: expanded,})}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label={(expanded) ? local.Component.StatusCard.Tooltip.detailExpanded : local.Component.StatusCard.Tooltip.detailCollapsed}
                                size="large">
                                <ExpandMoreIcon />
                            </IconButton>
                        </Tooltip>
                        }
                    </CardActions>
                    {canDisplayMore &&
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Alert severity="warning">
                                <AlertTitle>{local.Component.StatusCard.Alert.title}</AlertTitle>
                                {local.Component.StatusCard.Alert.textPeriod} <strong className={classes.underline}>{transformDateToStr(downStartDate)}</strong> {local.Component.StatusCard.Alert.dateLink} <strong className={classes.underline}>{transformDateToStr(downEndDate)}</strong>.<br/><br/>
                                {local.Component.StatusCard.Alert.textReason}<br/><strong>{reason}</strong>
                            </Alert>
                        </CardContent>
                    </Collapse>
                    }
                </>
            }
        </Card>)
    );
}

export default StatusCard;
