import { createTheme } from "@mui/material/styles";
import {blue, red} from "@mui/material/colors";

export const getTheme = (prefersDarkMode) => {
    return createTheme({
        palette: {
            mode: prefersDarkMode ? 'dark' : 'light',
            primary: blue,
            secondary: red,
            role: {
                guest: 'rgba(154,163,229,0.8)',
                reporter: '#354185',
                developer: '#040822',
                maintainer: '#d3d3e2',
                owner: '#7b3af6',
            }
        }
    });
};
