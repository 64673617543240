import React from "react";
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import CodeIcon from '@mui/icons-material/Code';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TrainingIcon from '@mui/icons-material/School';
import HelpIcon from '@mui/icons-material/Help';
import PolicyIcon from '@mui/icons-material/Policy';
import Home from "../View/Home";
import Members from "../View/Members";
import Projects from "../View/Projects";
import Status from "../View/Status";
import Training from "../View/Training";
import About from "../View/About";
import LegalNotice from "../View/LegalNotice";

export const pages = [[
    {
        element: Home,
        path: "/",
        icon: <HomeIcon/>
    },
], [
    {
        element: Members,
        path: "/team",
        icon: <PeopleIcon/>
    },
    {
        element: Projects,
        path: "/projects",
        icon: <CodeIcon/>
    },
    {
        element: Status,
        path: "/status",
        icon: <CheckCircleIcon/>
    }
], [
    {
        element: Training,
        path: "/training",
        icon: <TrainingIcon/>
    },
    {
        element: About,
        path: "/about",
        icon: <HelpIcon/>
    },
    {
        element: LegalNotice,
        path: "/legal_notices",
        icon: <PolicyIcon/>
    },
]];
