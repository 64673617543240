import React, {useContext} from 'react';
import * as PropTypes from "prop-types";

import clsx from 'clsx';
import {Button, CircularProgress, IconButton, Snackbar, SnackbarContent} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {amber, green} from '@mui/material/colors';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import red from "@mui/material/colors/red";
import LanguageContext from "../LanguageContext";

SnackbarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    hasUndoButton: PropTypes.bool,
    onUndo: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'successUndo', 'warning', 'error', 'info', 'wait']).isRequired,
};

SnackBarComponent.propTypes = {
    message: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['success', 'successUndo', 'warning', 'error', 'info', 'wait']).isRequired,
    onUndo: PropTypes.func,
};

const variantIcon = {
    success: CheckCircleIcon,
    successUndo: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
    wait: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    successUndo: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: red[600],
    },
    info: {
        backgroundColor: theme.palette.secondary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    wait: {
        backgroundColor: theme.palette.secondary.main,
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

function SnackbarContentWrapper(props){
    const classes = useStyles1();
    const {className, message, onClose, hasUndoButton, onUndo, variant} = props;
    const Icon = variantIcon[variant];
    const action = [
        <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
            size="large">
            <CloseIcon className={classes.icon}/>
        </IconButton>
    ];

    const local = useContext(LanguageContext);

    const undoAction = [
        <Button key="undo" aria-label="Undo" color="inherit" size="small" onClick={onUndo}>
            {local.Component.SnackBarComponent.undoButton}
        </Button>
    ];

    return (
        <SnackbarContent className={clsx(classes[variant], className)} aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    {variant==="wait"
                        ?<CircularProgress color={"primary"} size={20} className={classes.iconVariant}/>
                        :<Icon className={clsx(classes.icon, classes.iconVariant)} />}
                    {message}
                </span>
            }
            action={(hasUndoButton !== undefined) && (hasUndoButton === true)
                ? undoAction.concat(action)
                : action}
        />
    );
}


export default function SnackBarComponent({message, type, onUndo}){
    const [open, setOpen] = React.useState(true);

    function handleClose(event, reason){
        if(reason === 'clickaway'){
            return;
        }
        setOpen(false);
    }

    function handleUndo(){
        onUndo();
        setOpen(false);
    }

    React.useEffect(() => {
        if((open === false) && (message !== '') && (type === "wait")){
            setOpen(true);
        }
    }, [open, message, type]);

    return (
        <div>
            <Snackbar
                autoHideDuration={6000}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                onClose={handleClose}
                open={open}
            >
                <SnackbarContent
                    message={message}
                    action={
                        <>
                            {type === 'successUndo' && (
                                <Button color="secondary" size="small" onClick={handleUndo} aria-label={"Undo"}>
                                    Undo
                                </Button>
                            )}
                            <IconButton size="small" color="inherit" onClick={handleClose}>
                                <CloseIcon fontSize="small"/>
                            </IconButton>
                        </>
                    }
                />
            </Snackbar>
        </div>
    );
}
