import axios from "axios";

const client = axios.create();

export const getHealth = (url) => {
    return client.get(`${url}/healthcheck`)
        .then(res => {
            return {"up" : res.status === 200, "unavailable": res.status === 503};
        }).catch(res => {
            return {"up" : res.status === 200, "unavailable": res.status === 503};
        });
};
