import React, {useContext} from 'react';
import * as PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import {Card, CardActions, CardContent, CardHeader, CardMedia, IconButton, Tooltip, Typography} from "@mui/material";
import MovieIcon from '@mui/icons-material/Movie';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import LanguageContext from "../LanguageContext";

TrainingCard.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    video: PropTypes.string.isRequired,
    pdf: PropTypes.string.isRequired,
    icon: PropTypes.any.isRequired
};

function useStyles() {
    return makeStyles(() => ({
        root: {
            maxWidth: 345
        }
    }));
}

function TrainingCard ({title, text, video, pdf, icon}) {
    const classes = useStyles();
    const local = useContext(LanguageContext);

    return (
        (<Card classes={classes.root}>
            <CardHeader title={title}/>
            <CardMedia sx={{height: "25%", width: "25%", margin: "auto"}} component={icon} title={"Icon "+title}/>
            <CardContent>
                <Typography paragraph>{text}</Typography>
            </CardContent>
            <CardActions disableSpacing>
                <Tooltip title={local.Component.TrainingCard.Tooltip.video}>
                    <IconButton
                        component={"a"}
                        href={video}
                        rel="noopener"
                        target="_blank"
                        aria-label={local.Component.TrainingCard.IconButton.video}
                        size="large">
                        <MovieIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={local.Component.TrainingCard.Tooltip.pdf}>
                    <IconButton
                        component={"a"}
                        href={pdf}
                        rel="noopener"
                        target="_blank"
                        aria-label={local.Component.TrainingCard.IconButton.pdf}
                        size="large">
                        <PictureAsPdfIcon />
                    </IconButton>
                </Tooltip>
            </CardActions>
        </Card>)
    );
}

export default TrainingCard;
