import React, {useContext} from 'react';
import ReactMarkdown from "react-markdown";
import * as PropTypes from "prop-types";
import { makeStyles } from '@mui/styles';
import { Avatar, Button, Divider, Grid2 as Grid, IconButton, Link, Paper, Typography } from '@mui/material';
import Skeleton from "@mui/material/Skeleton";
import EmailIcon from '@mui/icons-material/Email';
import LanguageContext from "../LanguageContext";

ProjectCard.propTypes = {
    avatar: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    web_url: PropTypes.string.isRequired,
    issue_mail: PropTypes.string,
    issue_open: PropTypes.number.isRequired,
    loading: PropTypes.bool,
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 400,
    },
    large: {
        width: "10vh",
        height: "10vh",
        maxWidth: theme.spacing(10),
        maxHeight: theme.spacing(10),
    },
    loading: {
        margin: 'auto',
    },
    loadingTitle: {
        margin: 'auto',
        borderRadius: 16
    }
})
);

export default function ProjectCard({avatar, name, description, updated_at, web_url, issue_mail, issue_open, loading}) {
    const classes = useStyles();
    const local = useContext(LanguageContext);

    let updateTime = (Date.now() - new Date(updated_at));
    //Update time is over 24 h ?
    let updateTimeIsInHour = updateTime < (1000 * 24 * 3600);
    if(updateTimeIsInHour){
        updateTime = Math.round((updateTime / (1000 * 3600)));
    } else {
        updateTime = Math.round((updateTime / (1000 * 24 * 3600)));
    }

    return (
        (<div className={classes.root}>
            <Paper className={classes.paper} elevation={4}>
                <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                    {loading
                        ? <React.Fragment>
                            <Skeleton animation="wave" variant="circular" className={classes.large} />
                            <br/>
                            <Skeleton variant="rectangular" animation="wave" className={classes.loadingTitle} width={'50%'} height={'3vh'}/>
                            <br/>
                        </React.Fragment>
                        : <Avatar alt={name} src={avatar} className={classes.large} variant={"square"}/>
                    }
                    {loading && <Grid item size={1}/>}
                    {loading
                        ? <React.Fragment>
                            <Skeleton variant="text" animation="wave" className={classes.loading} width={'60%'}/>
                            <Skeleton variant="text" animation="wave" className={classes.loading} width={'60%'}/>
                            <Skeleton variant="text" animation="wave" className={classes.loading} width={'60%'}/>
                        </React.Fragment>
                        : <React.Fragment>
                            <Typography gutterBottom variant="h5" component="h5">
                                {name}
                            </Typography>
                            <Typography gutterBottom variant="subtitle1" color="textSecondary">
                                <ReactMarkdown children={description} components={{a: Link}} skipHtml/>
                            </Typography>
                            <br/>
                        </React.Fragment>
                    }
                </Grid>
                {loading && <React.Fragment><br/><br/></React.Fragment> }
                <Grid container alignItems="stretch" justifyContent="stretch" direction="column" spacing={1}>
                    <Grid container alignItems="center" justifyContent="center" flexWrap="nowrap">
                        <Grid item size={3} sx={{flexBasis: '100%'}}>
                            {loading
                                ? <Skeleton variant="rectangular" animation="wave" className={classes.loadingTitle} width={'40%'} height={'4vh'}/>
                                : <Typography gutterBottom variant="h6" component={"h6"}>
                                    {issue_open}
                                </Typography>
                            }
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item size={3} sx={{flexBasis: '100%'}}>
                            {loading
                                ? <Skeleton variant="circular" animation="wave" className={classes.loading} width={40} height={40}/>
                                : <IconButton
                                    aria-label="delete"
                                    component={Link}
                                    href={"mailto:" + ((issue_mail) ? issue_mail : "sia.contact@asso-insa-lyon.fr")}
                                    size="large">
                                    <EmailIcon />
                                </IconButton>
                            }
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item size={3} sx={{flexBasis: '100%'}}>
                            {loading
                                ? <Skeleton variant="rectangular" animation="wave" className={classes.loadingTitle} width={'60%'} height={'4vh'}/>
                                : <Typography gutterBottom variant="h6" component={"h6"}>
                                    {updateTime} {updateTimeIsInHour ? local.Component.ProjectCard.hours : local.Component.ProjectCard.days}
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-start" justifyContent="center" flexWrap="nowrap">
                        <Grid item size={3} sx={{flexBasis: '100%'}}>
                            <Typography gutterBottom variant="subtitle1" color="textSecondary">
                                {local.Component.ProjectCard.openIssue}
                            </Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item xs={3}  sx={{flexBasis: '100%'}}>
                            <Typography gutterBottom variant="subtitle1" color="textSecondary">
                                {local.Component.ProjectCard.createIssue}
                            </Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item xs={3} sx={{flexBasis: '100%'}}>
                            <Typography gutterBottom variant="subtitle1" color="textSecondary">
                                {local.Component.ProjectCard.lastActivity}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <br/>
                {loading
                    ? <Skeleton variant="rectangular" animation="wave" className={classes.loading} width={'40%'} height={'4vh'}/>
                    : <Button size="small" component={"a"} href={web_url} variant={"outlined"}>{local.Component.ProjectCard.seeProject}</Button>
                }
            </Paper>
        </div>)
    );
}
