import React from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BugReportIcon from '@mui/icons-material/BugReport';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import SettingsIcon from '@mui/icons-material/Settings';
import StarsIcon from '@mui/icons-material/Stars';

export default {
    GUEST: {id: 10, languageKey: "GUEST", getIcon: () => <AccountCircleIcon />, color: "secondary"},
    REPORTER: {id: 20, languageKey: "REPORTER", getIcon: () => <BugReportIcon />, color: "secondary"},
    DEVELOPER: {id: 30, languageKey: "DEVELOPER", getIcon: () => <DeveloperModeIcon />, color: "primary"},
    MAINTAINER: {id: 40, languageKey: "MAINTAINER", getIcon: () => <SettingsIcon />, color: "primary"},
    OWNER: {id: 50, languageKey: "OWNER", getIcon: () => <StarsIcon />, color: "primary"},
};
