import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Typography} from '@mui/material';
import {makeStyles} from "@mui/styles";
import {ExpandMore as ExpandMoreIcon, Remove as ExpandWaitingIcon} from '@mui/icons-material';
import Skeleton from "@mui/material/Skeleton";
import * as PropTypes from "prop-types";

AccordionItem.propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    chip: PropTypes.element,
    loading: PropTypes.bool,
};

function useStyles() {
    return makeStyles(theme => ({
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        }
    }));
}

function AccordionItem({title, id, loading, chip, children}) {
    const classes = useStyles();

    if(loading){
        return (
            (<Accordion expanded={false}>
                <AccordionSummary expandIcon={<ExpandWaitingIcon />} aria-controls={{id} + "-content"} id={{id} + "-header"}>
                    {chip && <Skeleton variant="rectangular" animation="wave" width={'15%'} height={'3vh'} style={{borderRadius: 50}}/>}
                    <Skeleton variant="rectangular" animation="wave" width={'70%'} height={'3vh'} style={ chip && {marginLeft: "20px"}}/>
                </AccordionSummary>
            </Accordion>)
        );
    }

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={{id} + "-content"} id={{id} + "-header"}>
                {chip}
                <Typography className={classes.heading}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{textAlign: "left"}}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
}

export default AccordionItem;
