import React from "react";
import {Helmet} from "react-helmet";
import * as PropTypes from "prop-types";

SeoMetaChanger.propTypes = {
    lang: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

function SeoMetaChanger ({lang, description, title}) {
    return (
        <Helmet defer={false}>
            <html lang={lang}/>
            <meta name="language" content={lang}/>

            <title>{title}</title>
            <meta name="title" content={title}/>
            <meta name="description" content={description}/>

            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>

            <meta property="twitter:title" content={title}/>
            <meta property="twitter:description" content={description}/>
        </Helmet>
    );
}

export default SeoMetaChanger;
