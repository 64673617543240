import React, {useContext} from "react";
import {Box, Link} from "@mui/material";
import LanguageContext, {LanguageProvider} from "../LanguageContext";
import DiscordIcon from "../Assets/Icon/DiscordIcon";
import GitLabIcon from "../Assets/Icon/GitLabIcon";
import FacebookIcon from '@mui/icons-material/Facebook';
import {makeStyles} from "@mui/styles";
import {getTheme} from "../Theme";
import Typography from "@mui/material/Typography";
const theme = getTheme(false);
const useStyles = makeStyles({
    icon : {
        [theme.breakpoints.up('sm')]: {
            fontSize : "25pt",
            margin : 20
        },  
        [theme.breakpoints.down('sm')]: {
            fontSize : "20pt",
            marginTop: 20,
            marginBottom: 20,
            marginRight:5,
            marginLeft:5, 
        }
    },
    copyrightBox : {
        color : "white",
        display: "inline-block",
        [theme.breakpoints.up('sm')]: {
            align: "left",
            padding : "2em"
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
            marginBottom: 20,
            marginLeft:10,
            align: "center",
            width: "60%"
        }
    },
    iconBox : {
        display: "inline-block",
        [theme.breakpoints.up('sm')]: {
            align: "right",
            marginRight:"2%",
        },
        [theme.breakpoints.down('sm')]: {
            align: "center",
            marginRight:"5%",
        }
    },
    endBox : {
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.up('sm')]: {
            alignItems: "center",
        },
        backgroundColor: theme.palette.primary[500],
    }
}
);
function Copyright() {
    const local = useContext(LanguageContext);
    const classes = useStyles();
    return (
        <Typography variant="body2" className={classes.copyrightBox}>
            <LanguageProvider value={local}>
                Copyright © <Link color="inherit" href="https://bde.insa-lyon.org/fr/content/sia">SIA INSA Lyon</Link>
                {' ' + new Date().getFullYear()} - <Link color="inherit" href="https://gitlab.com/sia-insa-lyon/dev/homepagesia/homepagesia-front">{local.Component.Footer.seeSection} GitLab </Link>
                - <Link color="inherit" href="/legal_notices">{local.Component.Footer.legalNotice}</Link>
            </LanguageProvider>
        </Typography>
    );
}
function ShowIcons() {
    const classes = useStyles();
    return(
        <Box className={classes.iconBox}>
            <Link href="https://discord.gg/m28dCzys">
                <DiscordIcon fill="white" className={classes.icon}/>
            </Link>
            <Link href="https://gitlab.com/sia-insa-lyon/homepagesia/homepagesia-front">
                <GitLabIcon colors={["white","white","white"]} className={classes.icon}/>
            </Link>
            <Link href="https://facebook.com/siainsalyon2">
                <FacebookIcon style={{ color:"white"}} url="https://facebook.com/siainsalyon2" className={classes.icon}/>
            </Link>
        </Box>
    );
}
function Footer() {
    const classes = useStyles();
    return (<footer><Box mt={8} className={classes.endBox}><Copyright/><ShowIcons/></Box></footer>);
}

export default Footer;
