import React, {useContext} from 'react';
import * as PropTypes from "prop-types";
import {Container, Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import config from "../config";
import LanguageContext from "../LanguageContext";
import {getProjectFile} from "../Request/GitLab/project";
import {getHealth} from "../Request/status";
import {getDefaultErrorMessage} from "../Request/requestErrors";
import SeoMetaChanger from "../Component/SeoMetaChanger";
import StatusCard from "../Component/StatusCard";

Status.propTypes = {
    errorHandler: PropTypes.func.isRequired,
};

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}));

function Status ({errorHandler}) {
    const classes = useStyles();
    const local = useContext(LanguageContext);

    const [loading, setLoading] = React.useState(false);
    const [siteList, setSiteList] = React.useState([]);

    React.useEffect((local) => {
        setLoading(true);
        getProjectFile(config.GESTION_PROJECT_ID, "Homepage/maintenance.json").then( res => {
            let sites = res['sites'];
            //We need to consult health endpoint of each website, promise can be resolved or rejected
            Promise.allSettled(sites.map((site) => getHealth(site['url'])))
                .then((value) => {
                    for(let i = 0; i < value.length ; i++){
                        sites[i]['isDown'] = !(('value' in value[i]) ? value[i].value.up : value[i].reason.up);
                        sites[i]['isUnavailable'] = ('value' in value[i]) ? value[i].value.unavailable : value[i].reason.unavailable;
                    }
                    setSiteList(sites);
                    setLoading(false);
                });
        }).catch((err) => {
            errorHandler(getDefaultErrorMessage(err, local));
        });
    }, [errorHandler]);

    return (
        <div className={classes.root}>
            <SeoMetaChanger lang={local.Lang} description={local.View.Status.seoDescription} title={local.View.Status.seoTitle}/>
            <Container maxWidth="md">
                <Typography variant="h3" component="h2" align="center" gutterBottom>
                    {local.View.Status.title}
                </Typography>
                <Typography variant="subtitle1" component="p" align="center" paragraph>
                    {local.View.Status.subtitle}
                </Typography>
            </Container>
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                {loading
                    ? [0, 1, 2, 3, 4, 5].map((s, id) =>
                        <Grid item size={{xs: 10, sm: 6, lg: 4, xl: 3}} key={`status_${id}`}>
                            <StatusCard loading={true}/>
                        </Grid>
                    )
                    : siteList.map((s, id) =>
                        <Grid item size={{xs: 10, sm: 6, lg: 4, xl: 3}} key={`status_${id}`}>
                            <StatusCard name={s.name} isDown={s.isDown} gitlab={s.repository} logo={s.logo || undefined}
                                link={s.url} loading={loading} downPeriod={s.downPeriod} isMaintained={s.isUnavailable}/>
                        </Grid>
                    )
                }
            </Grid>
        </div>
    );
}

export default Status;
