import React, {useEffect, useState} from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {CssBaseline, useMediaQuery} from "@mui/material";
import SnackBarComponent from "../Component/SnackBarComponent";
import {pages} from "../Route/Route";
import {getTheme} from "../Theme";
import AppDrawer from "../Component/AppDrawer";
import ErrorPage from "./Error";

function App(){
    const userWantsDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [errorMessage, setError] = useState("");
    const [theme, setTheme] = useState(getTheme(userWantsDarkMode));

    const toggleThemeModeChange = () => {
        setTheme(getTheme(theme.palette.mode === "light"));
    };

    useEffect(() => setTheme(getTheme(userWantsDarkMode)), [userWantsDarkMode]);

    const basename = process.env.PUBLIC_URL ? new URL(process.env.PUBLIC_URL).pathname : "/";

    const router = createBrowserRouter([
        {
            path: "/",
            element: <AppDrawer pages={pages} darkMode={theme.palette.mode === "dark"} changeThemeMode={toggleThemeModeChange}/>,
            errorElement: <ErrorPage/>,
            children: pages.flat().map((page) => {
                return {
                    path: page.path,
                    element: <page.element errorHandler={setError}/>,
                    errorElement: <ErrorPage />,
                };
            })
        },
    ], {basename: basename});

    return (
        <div className="App">
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <p hidden>{process.env.REACT_APP_API_PROD}</p>
                    <RouterProvider router={router}/>

                    {(errorMessage !== "") && <SnackBarComponent type={"error"} message={errorMessage}/>}
                </ThemeProvider>
            </StyledEngineProvider>
        </div>
    );
}

export default App;
