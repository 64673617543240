import React from 'react';

import * as PropTypes from "prop-types";
import hljs from 'highlight.js';
// HighLight.js theme for code block
import "../../node_modules/highlight.js/styles/monokai-sublime.css";

HighlightCode.propTypes = {
    children: PropTypes.node.isRequired,
    language: PropTypes.string          // See https://github.com/highlightjs/highlight.js/blob/master/SUPPORTED_LANGUAGES.md
};

function HighlightCode({children, language}) {
    const codeRef = React.createRef();

    React.useEffect(() => {
        hljs.configure({ignoreUnescapedHTML: true});
        hljs.highlightElement(codeRef.current);
    }, [codeRef]);

    return (
        <pre>
            <code ref={codeRef} className={language}>{children}</code>
        </pre>
    );
}

export default HighlightCode;
