import React, {useContext} from 'react';
import {Button, Grid, List, ListItem, ListItemText, Typography} from '@mui/material';
import {makeStyles} from "@mui/styles";
import AccordionItem from "../Component/AccordionItem";

import MailIcon from '@mui/icons-material/Mail';
import DiscordIcon from "../Assets/Icon/DiscordIcon";
import GitLabIcon from "../Assets/Icon/GitLabIcon";

import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator} from "@mui/lab";
import LanguageContext from "../LanguageContext";
import SeoMetaChanger from "../Component/SeoMetaChanger";

function useStyles() {
    return makeStyles(theme => ({
        root: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        container: {
            display: 'flex',
        },
        paper: {
            margin: theme.spacing(1),
        },
        desc: {
            padding_top: theme.spacing(10),
        },
        deposit: {
            alignSelf: 'baseline center',
        },
        '& > svg': {
            margin: theme.spacing(1),
        },
        button: {
            margin: theme.spacing(1),
        }
    }));
}

function About () {
    const classes = useStyles();
    const local = useContext(LanguageContext);

    return (
        <div className={classes.root} style={{margin: 20,}}>
            <SeoMetaChanger lang={local.Lang} description={local.View.About.seoDescription} title={local.View.About.seoTitle}/>
            <div className={classes.container}>
                <div title="Description SIA" className={classes.desc}>
                    <Typography variant="h3" component="h2" align="center" gutterBottom>
                        {local.View.About.title}
                    </Typography>
                    <Typography variant="subtitle1" component="p" align="center" paragraph>
                        {local.View.About.subtitle}
                    </Typography>
                </div>
                <br/>
                <AccordionItem id={"intro"} title={local.View.About.Accordion.intro.title}>
                    <Typography paragraph>
                        {local.View.About.Accordion.intro.content[0]}<br/><br/>
                        {local.View.About.Accordion.intro.content[1]}<br/><br/>
                        {local.View.About.Accordion.intro.content[2]}
                    </Typography>
                </AccordionItem>
                <AccordionItem id={"project"} title={local.View.About.Accordion.project.title}>
                    <div>
                        <Typography paragraph>
                            {local.View.About.Accordion.project.content[0]}
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={local.View.About.Accordion.project.content[1]}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={local.View.About.Accordion.project.content[2]}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={local.View.About.Accordion.project.content[3]}
                                />
                            </ListItem>
                        </List>
                    </div>
                </AccordionItem>
                <AccordionItem id={"contribute"} title={local.View.About.Accordion.contribute.title}>
                    <Typography paragraph>
                        {local.View.About.Accordion.contribute.content[0]}
                        <br/><br/>
                        {local.View.About.Accordion.contribute.content[1]}<br/>
                        {local.View.About.Accordion.contribute.content[2]}<br/>
                        {local.View.About.Accordion.contribute.content[3]}
                    </Typography>
                </AccordionItem>
                <AccordionItem id={"workload"} title={local.View.About.Accordion.workload.title}>
                    <Typography paragraph>
                        {local.View.About.Accordion.workload.content[0]}<br/><br/>
                        {local.View.About.Accordion.workload.content[1]}
                    </Typography>
                </AccordionItem>
                <AccordionItem id={"contact"} title={local.View.About.Accordion.contact.title}>
                    <div>
                        <Typography paragraph>
                            {local.View.About.Accordion.contact.content[0]}<br/>
                            {local.View.About.Accordion.contact.content[1]}
                        </Typography>
                        <br/><br/>
                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1} style={{textAlign: "center"}}>
                            <Grid item size={{xs: 12, md: 3, sm: 6}}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    className={classes.button}
                                    startIcon={<DiscordIcon fill="#7289DA"/>}
                                    aria-label="discord"
                                    component={"a"}
                                    href={"https://discord.gg/WVBSMm7"}
                                >
                                    {local.View.About.Accordion.contact.Button.discord}
                                </Button>
                            </Grid>
                            <Grid item msize={{xs: 12, md: 3, sm: 6}}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    className={classes.button}
                                    startIcon={<MailIcon />}
                                    aria-label="mail"
                                    component={"a"}
                                    href={"mailto:sia.contact@asso-insa-lyon.fr"}
                                >
                                    {local.View.About.Accordion.contact.Button.mail}
                                </Button>
                            </Grid>
                            <Grid item size={{xs: 12, md: 3, sm: 6}}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    className={classes.button}
                                    startIcon={<GitLabIcon/>}
                                    aria-label="gitlab"
                                    component={"a"}
                                    href={"https://gitlab.com/sia-insa-lyon"}
                                >
                                    {local.View.About.Accordion.contact.Button.gitlab}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </AccordionItem>
                <AccordionItem id={"history"} title={local.View.About.Accordion.history.title}>
                    <Timeline align="alternate">
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography color="textSecondary">{local.View.About.Accordion.history.Timeline[0].left}</Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography paragraph>
                                    {local.View.About.Accordion.history.Timeline[0].right}
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography color="textSecondary">{local.View.About.Accordion.history.Timeline[1].left}</Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color="primary" />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography paragraph>
                                    {local.View.About.Accordion.history.Timeline[1].right}
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography color="textSecondary">{local.View.About.Accordion.history.Timeline[2].left}</Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot/>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography paragraph>
                                    {local.View.About.Accordion.history.Timeline[2].right}
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography color="textSecondary">{local.View.About.Accordion.history.Timeline[3].left}</Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color="secondary" />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography paragraph>
                                    {local.View.About.Accordion.history.Timeline[3].right}
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography color="textSecondary">{local.View.About.Accordion.history.Timeline[4].left}</Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography paragraph>
                                    {local.View.About.Accordion.history.Timeline[4].right}
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography color="textSecondary">{local.View.About.Accordion.history.Timeline[5].left}</Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color="primary" />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography paragraph>
                                    {local.View.About.Accordion.history.Timeline[5].right}
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>
                </AccordionItem>
            </div>
        </div>
    );
}

export default About;
