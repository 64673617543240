export const local = {
    Lang: "fr",
    LangFormat: "fr-FR",
    Component: {
        Footer: {
            "seeSection": "Voir sur",
            "legalNotice" : "Mentions légales"
        },
        ProjectCard: {
            "hours": "heures",
            "days": "jours",
            "openIssue": " tickets ouverts",
            "createIssue": "Créer un ticket",
            "lastActivity": "Depuis la dernière activité",
            "seeProject": "Voir le projet"
        },
        RoleType: {
            GUEST: "Invité",
            REPORTER: "Rapporteur",
            DEVELOPER: "Développeur",
            MAINTAINER: "Mainteneur",
            OWNER: "Propriétaire"
        },
        SnackBarComponent: {
            "undoButton": "Annuler"
        },
        UserCard: {
            "seeProfile": "Voir le profil"
        },
        AccordionIssue: {
            "issueHeaderText": "Détails de l'issue",
            "issueInfoText": "Informations additionnelles",
            "creationDateText": "Date de création",
            "updateDateText": "Date de dernière mise à jour",
            "dateFormat": "fr-FR",
            "prefixDate": "Le",
            "missingDate": "Aucune date de disponible",
            "schedulingText": "Date de fin prévue",
            "missingSchedulingText": "Non définie",
            "assigneeText": "Personnes assignées",
            "missingAssigneeText": "En recherche de personnes",
            "tagText": "Mot-clefs :",
            "missingTagText": "Aucun mot-clef n'est associé à ce projet !",
            Button: {
                "gitlab": "Voir sur GitLab"
            }
        },
        AppDrawer: {
            translateButton: "Change to English"
        },
        StatusCard: {
            Tooltip: {
                "gitlab": "Voir le projet GitLab",
                "link": "Voir le site web",
                "detailCollapsed": "Voir plus",
                "detailExpanded": "Voir moins"
            },
            IconButton: {
                "gitlab": "Voir le projet GitLab",
                "link": "Voir le site web"
            },
            StateChip: {
                "down": "Site inaccessible",
                "maintaining": "Site en maintenance",
                "up": "Site accessible"
            },
            StateAlert: {
                "down": "Ce site est indisponible",
                "isMaintaining": " (maintenance en cours)",
                "hasPeriod": "Une maintenance aura lieu prochainement",
                "up": "Ce site est disponible"
            },
            Alert: {
                "title": "Ce site a une période de maintenance prévue",
                "textPeriod": "Ce site va être en maintenance du",
                "textReason": "La raison pour cette maintenance est :",
                "dateLink": "au"
            }
        },
        TrainingCard: {
            Tooltip: {
                "video": "Voir l'enregistrement",
                "pdf": "Aller au support de formation"
            },
            IconButton: {
                "video": "Voir l'enregistrement",
                "pdf": "Aller au support de formation",
                "expand": "Voir le détail"
            }
        }
    },
    Request: {
        error: {
            BadRequest: "Une erreur est survenue, veuillez recharger la page !",
            ErrorLogin: "Le token d'authentification a expiré, veuillez contacter un membre du SIA !",
            ErrorForbidden: "La ressource que vous tentez d'accèder vous est interdite !",
            ErrorNotFound: "Cette ressource est introuvable !",
            Default: "Une erreur est survenue, veuillez contacter un membre du SIA !",
        }
    },
    Route: {
        "/": "Accueil",
        "/team": "Equipes",
        "/projects": "Projets",
        "/status": "Maintenance",
        "/training": "Formations",
        "/about": "A propos",
        "/legal_notices" : "Mentions légales"
    },
    View: {
        About: {
            "title": "A propos du SIA",
            "subtitle": "Vous trouverez ici une courte FAQ, ainsi que diverses informations sur nous",
            "seoTitle": "A propos - SIA INSA Lyon",
            "seoDescription": "Découvrez le SIA, son histoire, ainsi qu'une FAQ pour répondre à vos questions",
            Accordion: {
                "intro": {
                    "title": "Qu’est ce que le SIA ?",
                    "content": [
                        "Le Service Informatique Associatif (SIA) est une équipe qui a été créée dans le but de proposer des services informatiques aux associations et à leurs membres. Notre champ d’action est large : nous pouvons effectuer la mise en place, l’hébergement ou encore le développement d’outils informatiques.\n",
                        "Nous souhaitons mettre en place des outils utiles à la Vie Associative du campus. Des outils qui faciliteront les interactions entre les associations et qui simplifieront leur gestion. Nous pensons qu’il est plus efficace de mutualiser les ressources afin de réaliser des outils plus adaptés et plus pérennes en centralisant et répertoriant les projets informatiques réalisés pour la VA.\n",
                        "Le SIA a également pour mission d’éduquer aux concepts de vie privée et de sécurité des données ainsi que de proposer des outils alternatifs, libres ou open-source et efficaces.\n"
                    ]
                },
                "project": {
                    "title": "Quels sont les projets du SIA ?",
                    "content": [
                        "Les projets du SIA sont de natures différentes, nous avons 3 principaux pôles :",
                        "Infrastructure : c’est la gestion des serveurs. On y fait du K8s, de la virtualisation, du réseau, du SysAdmin.",
                        "Développement : c'est le pôle principal du SIA. Les développeurs travaillent sur des projets qui sont réellement demandés et utilisés par la suite. On utilise surtout du ReactJS, du Django (Python) et du Laravel (PHP). Appartenir à ce pôle peut être une opportunité pour découvrir le développement de logiciel d'une manière différente de ce qui peut être appris à l'INSA",
                        "PPT : le Pôle Pas Technique, on y parle de protection des données, de comment on restreint l’accès aux infos qu’on a et on y gère la communication entre le SIA et les assos. Le but est d’avoir des gens motivés pour qu’on fasse des applications qui répondent aux besoins des assos en respectant le cadre légal et la vie privée de nos utilisateurs."
                    ]
                },
                "contribute": {
                    "title": "Qui peut contribuer au SIA ?",
                    "content": [
                        "Ça te parle ? L’idée te plaît ? Rejoins-nous !",
                        "Aujourd’hui le SIA est composé d’une dizaine de personnes, qui s’investissent dans les différents projets. Nous recherchons des personnes qui seraient intéressées pour nous aider. Tu n’as pas forcément besoin d’avoir des compétences techniques. Nous proposons des formations pour te faire découvrir nos projets et les technologies que nous utilisons si ça t’intéresse.",
                        "Pas envie de faire du code ? Nous avons aussi des projets non-techniques et nous cherchons des testeurs pour nos applications.",
                        "Au SIA tu peux participer sur un projet ou rejoindre l’équipe et travailler avec nous toute l’année selon tes disponibilités."
                    ]
                },
                "workload": {
                    "title": "La charge de travail",
                    "content": [
                        "La charge de travail au SIA c’est toi qui la définis !",
                        "Tu as le choix de travailler sur un ou plusieurs projets, à différents moments de l’année ou sur l’année entière !"
                    ]
                },
                "contact": {
                    "title": "Comment nous contacter ?",
                    "content": [
                        "Prends contact avec nous sur le discord VA tous les mercredis à partir de 18h ! Tu pourras rencontrer des membres du SIA et leur poser des questions !\n",
                        "Si tu n'aimes pas discord, tu peux toujours nous contacter par mail ou contribuer directement à un de nos projets."
                    ],
                    Button: {
                        "discord": "Discord de la VA",
                        "mail": "Envoyer un mail",
                        "gitlab": "Voir sur GitLab"
                    }
                },
                "history": {
                    "title": "Histoire du SIA",
                    Timeline: [
                        {"left": "2018", "right": "Benoît Renault lance l'idée du SIA"},
                        {"left": "Eté 2019", "right": "Les préparations pour la formation de l'embryon du SIA sont finies"},
                        {"left": "Rentrée 2019", "right": "Recrutement d'une dizaine de membres non Orga'IF au sein du SIA, Week-end de formation et validation de l'équipe SIA par le CA du BdE"},
                        {"left": "Fin 2019", "right": "Election du responsable SIA et formation du bureau du SIA (4 personnes issues de la DSI, du CA du BdE, du CVA et du SIA)"},
                        {"left": "Début 2020", "right": "Lancement du site de l'équipe, de nouveaux projets et d'un projet en collaboration avec des externes"},
                        {"left": "Juin 2020", "right": "Election du nouveau responsable SIA et première passation"}
                    ]
                },
            }
        },
        Home: {
            "title": "Le SIA (Service Informatique Associatif) est une équipe du BdE dont le but est de\n" +
                "fournir des services aux associations INSA Lyon.",
            "subtitle": "Si vous souhaitez obtenir des informations sur le SIA, rendez-vous à la section \"A propos\" !",
            "seoTitle": "SIA INSA Lyon",
            "seoDescription": "Découvrez le SIA, ses projets, son équipe, ses formations et plus encore",
            "captionText": "Ci-dessous, vous trouverez les tâches ouverte aux contributions extérieures au SIA. N'hésitez pas à les regardez si vous souhaitez nous venir en aide !",
            "missingIssueText": "Aucune issue n'est ouverte à la communauté pour le moment.",
            "searchPlaceholder": "Chercher par nom",
            "filterPlaceholder": "Trier",
            "filterProjectPlaceholder": "Filtrer par projet",
            "filterProjectResume": " projets sélectionnés",
            "filterNames": ["Aucun", "Par la plus récente créée", "Par la plus récenté active"]
        },
        Members: {
            "title": "Liste des membres de l'équipe du SIA",
            "subtitle": "Vous trouverez ici la liste des membres actifs sur GitLab",
            "seoTitle": "Membres - SIA INSA Lyon",
            "seoDescription": "Retrouvez ici l'ensemble des membres actifs du SIA"
        },
        Projects: {
            "title": "Liste des projets de l'équipe du SIA",
            "subtitle": "Vous trouverez ici la liste des projets présents sur GitLab. Les projets ci-présents sont ouverts\n" +
                "aux contributions, n'hésitez pas à y jeter un coup d'oeil, voir même à un participer !\n",
            "seoTitle": "Projects - SIA INSA Lyon",
            "seoDescription": "Retrouvez ici les projets menés par le SIA"
        },
        Status: {
            "title": "Liste des applications déployés avec leur état",
            "subtitle": "Vous trouverez ici une liste d'application et si elles sont en ligne, en maintenance ou hors ligne !\n",
            "seoTitle": "Maintenance - SIA INSA Lyon",
            "seoDescription": "Retrouvez ici l'état de fonctionnement actuel de nos applications déployées"
        },
        Training: {
            "title": "Liste des formations",
            "subtitle": "Vous trouverez ici les formations délivrées durant les week-ends du SIA !\n",
            "seoTitle": "Formations - SIA INSA Lyon",
            "seoDescription": "Regardez et trouvez ici les formations proposées par le SIA",
        },
        LegalNotice: {
            "title": "Informations Légales",
            "items": [{ "title": "Informations relatives au site",
                "text": [" Le présent site Internet (https://sia.asso-insa-lyon.fr) est la propriété de : ", "BDE INSA Lyon",
                    "Association de loi 1901", "Siège social : Maison des Étudiants - 20 avenue Albert Einstein - 69621 VILLEURBANNE CEDEX - FRANCE",
                    "SIRET : 380 438 358 000 18",
                    "Code APE : 9499Z", "Responsable de publication : Président.e du BDE, Responsable SIA","Contact : sia.contact@asso-insa-lyon.fr",
                    "Ce site est soumis à la législation française, et toute contestation ou litiges liés à ce site seront de " +
                    "la compétence exclusive des tribunaux dont dépend le siège social de l'association."
                ]

            },
            {
                "title": "Informations relatives à l'hébergeur",
                "text": ["GitLab France", "SAS au capital de 10 000 €", "RCS Paris 899 797 492", "Code APE : 6201Z",
                    "N° TVA : FR 52 899 797 492", "Siège social : 57 rue d'Amsterdam, 75008 PARIS 8 - FRANCE"
                ]
            },
            {
                "title": "Crédits",
                "text": ["Visuels de la page XXXXXX : NOM Prénom"]
            },
            {
                "title": "Consultation et accessibilité",
                "text": ["Le SIA ne peut être tenu pour responsable des aléas de connexion à internet, de fonctionnement " +
                    "du navigateur, de l'ordinateur ou tout autre périphérique de connexion, de connexion aux serveurs, " +
                    "de l'envoi des formulaires à une adresse erronée ou incomplète, de dysfonctionnement des serveurs " +
                    "de notre hébergeur, d'erreurs informatiques quelconques ou défauts constatés sur le site."
                ]
            },
            {
                "title": "Limitation de responsabilité",
                "text": ["Malgré tout nos soins, les informations contenues sur ce site peuvent parfois être incomplètes," +
                    " comporter des omissions, des erreurs ou des lacunes. Merci de bien vouloir signaler toute erreur, " +
                    "lacune ou dysfonctionnement via sia@asso-insa-lyon.fr en détaillant le problème, " +
                    "la manière de reproduire ce dernier si besoin, et le navigateur utilisé.",
                "Le SIA ne pourra être tenu responsable d'un quelconque dommage matériel, ou perte de données, " +
                    "subi par le matériel informatique de l'utilisateur à la suite de l'utilisation du présent site " +
                    "internet et du téléchargement du contenu de ce dernier.",
                "Par ailleurs, le SIA ne pourra être tenu responsable des liens hypertextes présents sur" +
                    " ce site pointant vers d'autres ressources présentes sur Internet. Il ne pourra pas être tenu " +
                    "responsable de la disponibilité, du contenu, de la consultation, ou des dommages liés à la consultation," +
                    " de ces dites ressources."
                ]
            },
            {
                "title" : "Données personnelles",
                "text" : ["D'une façon générale, vous pouvez visiter notre site sur Internet sans avoir à décliner votre" +
                        " identité et à fournir des informations personnelles vous concernant.",
                "Cependant, pour des besoins évidents de fonctionnement du site, nous nous réservons le droit de collecter :",
                "- Vos préférences idiomatiques pour afficher les pages de ce site dans la langue appropriée",
                "- Vos préférences de couleur de thème",
                "Si vous souhaitez faire adresser une demande concernant vos données (que ce soit pour vous s'opposer" +
                        " à leurs traitements, les supprimer, y accéder ou à les rectifier), ou si vous avez des questions" +
                        " concernant le traitement, il vous est possible de contacter le SIA par les biais suivants :",
                "- En message privé via les réseaux sociaux du SIA (Facebook, Gitlab, Discord);",
                "- En contactant le directeur de publication directement.",
                "Nous vous demanderons de bien fournir, pour chaque demande, une copie recto/verso d'une pièce d'identité" +
                        " (carte d’identité ou passeport) et une attestation sur l’honneur. À titre d'information, et au" +
                        " regard du Règlement Général sur la Protection des Données (RGPD), nous vous invitons à consulter" +
                        " vos droits sur le site internet de la CNIL.",
                "Si après nous avoir contacté, vous estimez que vos droits ne sont pas respectés, nous vous prions" +
                        " de noter que vous avez le droit de déposer une plainte auprès de la CNIL concernant le traitement" +
                        " des données par l'association si vous jugez cela nécessaire."
                ]


            },
            {
                "title" : "Droit de propriété intellectuelle",
                "text" : ["Le présent site, ainsi que son contenu (logos, icônes, images ...), sont protégés au titre du " +
                    "droit d'auteur et de la propriété intellectuelle. Ils appartiennent au service, à des personnes" +
                    " physiques (voir la section des crédits) ou à d'autres entreprises.",
                "Toute reproduction, utilisation, ou représentation, quel que soit le support ou le procédé, de tout" +
                    " ou partie du site et/ou de son contenu nécessite le consentement du SIA, ou des tiers associés au " +
                    "contenu.",
                "Toute marques ou logos autres que celles de l'association sont la propriété unique et absolue des tiers" +
                    " auxquelles ils appartiennent. Le SIA n'a donc aucun droit sur ces derniers, mais ils restent tout de même" +
                    " soumis à la protection du droit d'auteur et de la propriété intellectuelle. Si vous souhaitez utiliser" +
                    " ces derniers, rapprochez-vous auprès de ces tiers pour connaître les modalités d'utilisations et de" +
                    " reproduction.",
                "Pour toute demande d'autorisation concernant notre marque ou notre contenu, veuillez contacter le " +
                    "directeur de publication."
                ]
            },
            {
                "title" : "Responsabilité de l'utilisateur",
                "text" : ["Toute tentative d'attaque, d'intrusion, ou d'utilisation frauduleuse de la part de l'utilisateur" +
                    " du présent site relève de la responsabilité de l'utilisateur.",
                "Sauf autorisation expresse du service, notamment à des buts de pentesting, vous vous exposez à des" +
                    " poursuites civiles et/ou pénales conformément au droit français.",
                "Le SIA se réserve également la possibilité d'engager la responsabilité civile et/ou pénale de l’utilisateur, " +
                    "notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit " +
                    "le support utilisé (texte, information saisie via un des formulaires, mail, photographie ...).",
                "En tant qu'utilisateur, vous êtes tenu responsable de votre navigation lorsque vous consultez des " +
                    "ressources externes à ce site."
                ]
            },
            {
                "title" : "Évolution des informations légales",
                "text" : ["Le SIA se réserve le droit de modifier le contenu et les dispositions des présentes " +
                    "informations légales à tout moment et sans justification.",
                "Si vous souhaitez faire adresser une remarque, des suggestions, ou faire remonter une erreur concernant" +
                    " les présentes informations légales, nous vous invitons à nous contacter :",
                "- En message privé via les réseaux sociaux de l'association (Facebook, Twitter, Discord) ;",
                "- En contactant le directeur de publication directement.",
                ]
            }
            ],

        }

    }
};
