export const local = {
    Lang: "en",
    LangFormat: "en-US",
    Component: {
        Footer: {
            "seeSection": "See on",
            "legalNotice" : "Legal notices"
        },
        ProjectCard: {
            "hours": "hours",
            "days": "days",
            "openIssue": " Issues opened",
            "createIssue": "Create an issue",
            "lastActivity": "Since the last activity",
            "seeProject": "See project"
        },
        RoleType: {
            GUEST: "Guest",
            REPORTER: "Reporter",
            DEVELOPER: "Developer",
            MAINTAINER: "Maintainer",
            OWNER: "Owner"
        },
        SnackBarComponent: {
            "undoButton": "Undo"
        },
        UserCard: {
            "seeProfile": "See profile"
        },
        AccordionIssue: {
            "issueHeaderText": "Issue details",
            "issueInfoText": "Additional information",
            "creationDateText": "Creation date",
            "updateDateText": "Last update date",
            "dateFormat": "en-GB",
            "prefixDate": "At",
            "missingDate": "No date available",
            "schedulingText": "Ending date scheduled",
            "missingSchedulingText": "None",
            "assigneeText": "Assigned people",
            "missingAssigneeText": "In search of people",
            "missingIssueText": "There is no issue open that can be completed by the community.",
            "tagText": "Key words:",
            "missingTagText": "No key words is associated to this project!",
            Button: {
                "gitlab": "See on GitLab"
            }
        },
        AppDrawer: {
            translateButton: "Passer au français"
        },
        StatusCard: {
            Tooltip: {
                "gitlab": "See GitLab repository",
                "link": "See the website",
                "detailCollapsed": "See more",
                "detailExpanded": "See less"
            },
            IconButton: {
                "gitlab": "See GitLab repository",
                "link": "See the website"
            },
            StateChip: {
                "down": "Unavailable website",
                "maintaining": "Website in maintenance",
                "up": "Available website"
            },
            StateAlert: {
                "down": "This website is unavailable",
                "isMaintaining": " (maintenance in progress)",
                "hasPeriod": "A maintenance will be taking place soon",
                "up": "This website is available"
            },
            Alert: {
                "title": "This website has a planned maintenance period",
                "textPeriod": "This website will be in maintenance from",
                "textReason": "The reason for this maintenance is:",
                "dateLink": "to"
            }
        },
        TrainingCard: {
            Tooltip: {
                "video": "See the recording",
                "pdf": "See the training support"
            },
            IconButton: {
                "video": "See the recording",
                "pdf": "See the training support",
                "expand": "See details"
            }
        }
    },
    Request: {
        error: {
            BadRequest: "An error happened, please refresh the page!",
            ErrorLogin: "The authentication token has expired, please contact a SIA member!",
            ErrorForbidden: "You are trying to access to a forbidden resource!",
            ErrorNotFound: "This resource cannot be found!",
            Default: "An error happened, please contact a SIA member!",
        }
    },
    Route: {
        "/": "Home",
        "/team": "Team",
        "/projects": "Projects",
        "/status": "Application status",
        "/training": "Training",
        "/about": "About SIA",
        "/legal_notices":"Legal notices"
    },
    View: {
        About: {
            "title": "About the SIA",
            "subtitle": "You can find here a brief FAQ, as well as various information on us",
            "seoTitle": "About - SIA INSA Lyon",
            "seoDescription": "Discover the SIA, our history, and the FAQ section to answer your questions",
            Accordion: {
                "intro": {
                    "title": "What is the SIA ?",
                    "content": [
                        "The Service Informatique Associatif (SIA) is a team which has been created with the goal to provide developement services to associations and their members. Our action field is wide: we can deploy, host or even develop software.\n",
                        "We wants to set up useful tools for the associative life of our campus. Theses tools will facilitate the interaction between the different associations of the campus and will simplify their management. In order to build more adapted and sustainable tools, we believe it is more efficient to mutualize the resources and projects created by the associative life.\n",
                        " One of the missions of the SIA is also to educate students on privacy and data security concepts. It aims to propose alternative tools that are free or open source and efficient.\n"
                    ]
                },
                "project": {
                    "title": "What can be a SIA project ?",
                    "content": [
                        "SIA project can be of different kind, we have 3 main departments :",
                        "Infrastructure : it is the management of servers. We touch to K8s, virtualization, network, and SysAdmin.",
                        "Software development : it is the main part of the SIA. Developers work on projects that are really needed and used afterward. We use principally ReactJS, Django (Python) and Laravel (PHP). Belong to this departement can be the opportunity to discover software development in a different way from what can be taught at the INSA.",
                        "PPT : the Non Technical Department, we talk about data protection, about how we restrict access to information that we have, and how we manage the communication between the SIA and the associations. The goal is to have motivated people in order to create apps that respond to the needs of associations, while respecting the law and the privacy of our users."
                    ]
                },
                "contribute": {
                    "title": "Who can contribute to the SIA ?",
                    "content": [
                        "Are inspired by all of this? Do you like the idea? Join us !",
                        "Nowadays, the SIA is composed of a dozen of people, who are invested in many different kind of projects. We search people that are interessed to help us. You don't necessarily need to have technical skills. We propose different kinds of formations to allow you to discover our projects and the techniques that we use, if you are interested.",
                        "You don't wants to code? We also have non-technical projects and we are looking for people to test our apps.",
                        "In the SIA, you can participate on a project or join the team and work with us the whole year according to your availability."
                    ]
                },
                "workload": {
                    "title": "What is the workload ?",
                    "content": [
                        "The workload with the SIA is defined by you, and only you!",
                        "You have the choice to work on one or many projects, at different time of the year or during the whole year!"
                    ]
                },
                "contact": {
                    "title": "How to contact us ?",
                    "content": [
                        "You can contact us on the associative life discord server every wednesday (from 6pm)! You will be able to meet members of the SIA team and ask them questions there!\n",
                        "If you dislike discord, you can still contact us by mail, or contribute directly to one of our projects."
                    ],
                    Button: {
                        "discord": "Discord of VA",
                        "mail": "Send a mail",
                        "gitlab": "See on GitLab"
                    }
                },
                "history": {
                    "title": "History of SIA",
                    Timeline: [
                        {"left": "2018", "right": "Benoît Renault propose the idea of the SIA"},
                        {"left": "Summer 2019", "right": "The preparation to form the base of the SIA are over"},
                        {"left": "Start of winter semester 2019", "right": "Recruitment of a dozen of members (non-affiliated to Orga'IF team) into the SIA, Formation Week-end and validation of the SIA team by the CA of the BdE"},
                        {"left": "End 2019", "right": "Election of a person in charge of the SIA and formation of the SIA office (4 peoples from the DSI, the CA of BdE, the CVA and the SIA)"},
                        {"left": "Start 2020", "right": "Launch of the website, of new projects and of a project in collaboration with external contributors"},
                        {"left": "June 2020", "right": "Election of the new person in charge of the SIA and first handover of power"}
                    ]
                },
            }
        },
        Home: {
            "title": "The SIA (Service Informatique Associatif) is a team of the INSA Lyon BdE which has the vocation\n" +
                "to provide services to the INSA Lyon associations.",
            "subtitle": "If you want to get information about the SIA, please check the \"About SIA\" section!",
            "seoTitle": "SIA INSA Lyon",
            "seoDescription": "Discover the SIA, our projects, team, trainings and more",
            "captionText": "You will find below task that are open to external contribution. Don't hesitate to look at them if you want to help us!",
            "searchPlaceholder": "Search by name",
            "filterPlaceholder": "Sort",
            "filterProjectPlaceholder": "Filter by project",
            "filterProjectResume": " selected project",
            "filterNames": ["None", "Last created", "Last updated"]
        },
        Members: {
            "title": "List of the members of the SIA's team",
            "subtitle": "You can find here the list of our active members on GitLab",
            "seoTitle": "Members - SIA INSA Lyon",
            "seoDescription": "See and find the members of the SIA's team"
        },
        Projects: {
            "title": "List of projects conducted by the SIA's team",
            "subtitle": "You can find here a list of projects available on GitLab. Theses projects are open\n" +
                "to contributions, don't hesitate to look at them, or even participate on one!\n",
            "seoTitle": "Projects - SIA INSA Lyon",
            "seoDescription": "See and find the projects of the SIA's team"
        },
        Status: {
            "title": "List of deployed applications with status",
            "subtitle": "You can find here a list of applications and their maintenance status\n",
            "seoTitle": "Applications status - SIA INSA Lyon",
            "seoDescription": "See and find the current status of our deployed applications"
        },
        Training: {
            "title": "List of training (Only in French)",
            "subtitle": "You can find here a list of trainings hold during the SIA week-ends!\n",
            "seoTitle": "Training - SIA INSA Lyon",
            "seoDescription": "See and find the training proposed by the SIA's team (only in french)"
        },
        LegalNotice: {
            "title": "Legal notices",
            "items": [{ "title": "Website information",
                "text": ["This website (https://sia.asso-insa-lyon.fr) belongs to : ", "BDE INSA Lyon",
                    "Association of law 1901", "Headquarters: Maison des Étudiants - 20 avenue Albert Einstein - 69621 VILLEURBANNE CEDEX - FRANCE",
                    "SIRET : 380 438 358 000 18",
                    "APE Code: 9499Z", "Publication responsible : President of the BDE, Responsible of the SIA","Contact : sia.contact@asso-insa-lyon.fr",
                    "This website is under French laws, and all protests linked to this website will fall within the jurisdiction of" +
                        " courts whose association's headquarters depend on."
                ]

            },
            {
                "title": "Host Information",
                "text": ["GitLab France", "SAS au capital de 10 000 €", "RCS Paris 899 797 492", "APE Code: 6201Z",
                    "N° TVA : FR 52 899 797 492", "Headquarters : 57 rue d'Amsterdam, 75008 PARIS 8 - FRANCE"
                ]
            },
            {
                "title": "Credits",
                "text": ["Graphic elements XXXXXX : NOM Prénom"]
            },
            {
                "title": "Consultation and accessibility",
                "text": ["The SIA isn't responsible in any cases of network connection issues, of web browser work, of computer or " +
                "whatever which can connect to the website, of servers connection, " +
                "of form sendings to an incomplete or false address, of servers dysfunction " +
                "of our host, of bugs or any mistakes seen on this website."
                ]
            },
            {
                "title": "Responsibility limits",
                "text": ["Despite all our attention, website contents can be incomplete or can have omissions or mistakes" +
                ". Please report any bug or error," +
                "via sia@asso-insa-lyon.fr detailing your issue, " +
                "the way to reproduce it, and the used browser.",
                "The SIA won't be responsible of any material damages or loss of datas " +
                    "suffered by the user computing tools after using this website and downloading content on it.",
                "Besides, the SIA won't be responsible of links present on" +
                    " this site pointing towards other resources present on Internet. It cannot be held " +
                    " responsible for the availability, content, consultation, or damages related to the consultation, " +
                    " of these resources."
                ]
            },
            {
                "title" : "Personal datas",
                "text" : ["In general, you can visit our site on the Internet without having to identify yourself and provide personal information about yourself." +
                "You can visit our site on the Internet without having to identify yourself or provide any personal information about yourself.",
                "However, for good-working needs of this website, we can collect :",
                "- Your language preferences",
                "- Your color's theme preferences",
                "If you wish to make a request concerning your data (whether to oppose" +
                " to their processing, delete them, access them or rectify them), or if you have any questions" +
                " about the treatment, you can contact the SIA through the following channels:",
                "- In DM using the SIA social networks (Facebook, Twitter, Discord) ;",
                "- Directly the director of publication.",
                "For each request, we will ask you to provide a copy of both sides of an identity document " +
                    "(identity card or passport) and a certificate of honour. For your information, and" +
                    " with regard to the General Data Protection Regulation (RGPD), we invite you to consult" +
                    " your rights on the website of the CNIL.",
                "If, after contacting us, you feel that your rights have not been respected, please" +
                    " note that you have the right to file a complaint with the CNIL concerning the processing " +
                    "of the data by the association if you consider this necessary."
                ]


            },
            {
                "title" : "Intellectual property right",
                "text" : ["This site, as well as its content (logos, icons, images ...), are protected under the " +
                    " copyright and intellectual property. They belong to the service, to " +
                    " physical persons (see the credits section) or to other companies.",
                "Any reproduction, use or representation, whatever the medium or process, of all" +
                    " or part of the site and/or its content requires the consent of the SIA, or of third parties associated with the content.",
                "Any trademarks or logos other than those of the association are the sole and absolute property of the third parties " +
                    " to which they belong. The SIA has no right to them, but they are subject to copyright and intellectual property protection." +
                    " If you wish to use them, please contact these third parties to know the terms of use and reproduction.",
                "For any authorization request concerning our brand or our content, please contact the director of publication."
                ]
            },
            {
                "title" : "User responsibility",
                "text" : ["Any attack attempt, intrusion, or fraudulent use of this website from the user " +
                "comes under his own responsibility.",
                "Unless you are allowed by the SIA, for pentesting goals for example, you expose yourself to" +
                    " civilian and/or penal pursuits according to the French right.",
                "The SIA also keeps the possibility to engage the civilian/penal responsibility of the user, " +
                    "particularly for racist, abusive, slanderous or pornographic messages linked, what would be" +
                    "used tools (text, form information, mail, photograph ...).",
                "As user, you are responsible of your browsing when you are consulting external resources."
                ]
            },
            {
                "title" : "Updates of the legal notices",
                "text" : ["The SIA keeps rights to modify the content and these" +
                "legal notices whenever it needs and without any justification.",
                "If you would like to share remarks, suggests, or to notice a mistake" +
                    " in these legal notices, please contact :",
                "- In DM using the SIA social networks (Facebook, Twitter, Discord) ;",
                "- Directly the director of publication."
                ]
            }
            ],

        }
    }
};
