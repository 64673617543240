import React, {useContext} from 'react';
import {Divider, Grid2 as Grid, Typography} from '@mui/material';
import {makeStyles} from "@mui/styles";
import LanguageContext from "../LanguageContext";
import SeoMetaChanger from "../Component/SeoMetaChanger";
import TrainingCard from "../Component/TrainingCard";
import {TRAINING_INFO} from "./Content/training";

function useStyles() {
    return makeStyles(theme => ({
        root: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        container: {
            display: 'flex',
        },
        paper: {
            margin: theme.spacing(1),
        },
        desc: {
            padding_top: theme.spacing(10),
        }
    }));
}

function Training () {
    const classes = useStyles();
    const local = useContext(LanguageContext);

    return (
        <React.Fragment>
            <SeoMetaChanger lang={local.Lang} description={local.View.Training.seoDescription} title={local.View.Training.seoTitle}/>
            <div className={classes.root} style={{margin: 20,}}>
                <div className={classes.container}>
                    <div title="Formation SIA" className={classes.desc}>
                        <Typography variant="h3" component="h2" align="center" gutterBottom>
                            {local.View.Training.title}
                        </Typography>
                        <Typography variant="subtitle1" component="p" align="center" paragraph>
                            {local.View.Training.subtitle}
                        </Typography>
                    </div>
                    {TRAINING_INFO.map((t => {
                        return(
                            <React.Fragment key={"section_"+t.year}>
                                <Typography variant="h4" component="h4" align="left" gutterBottom>{t.year}</Typography>
                                <Divider/>
                                <br/>
                                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                                    {t.trainings.map((e, i)=> {
                                        return (
                                            <Grid item size={{xs: 10, sm: 6, md: 4, lg: 4, xl: 4}} key={"card"+t.year+"_"+i}>
                                                <TrainingCard title={e.name} text={e.description} video={e.peertube_link} pdf={e.pdf_link} icon={e.logo}/>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </React.Fragment>
                        );
                    }))}
                </div>
            </div>
        </React.Fragment>
    );
}

export default Training;
