import React, {useContext} from 'react';
import { Chip } from '@mui/material';
import { createStyles } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import * as PropTypes from "prop-types";
import LanguageContext from "../LanguageContext";

UserRoleComponent.propTypes = {
    role: PropTypes.object.isRequired,
};

const useStyles = makeStyles(
    createStyles(theme => ({
        chip: {
            margin: theme.spacing(0.5),
        },
    })
    )
);

function UserRoleComponent({role}) {
    const classes = useStyles();
    const local = useContext(LanguageContext);

    return ((Object.getOwnPropertyNames(role).length !== 0)
        && <Chip color={role.color} label={local.Component.RoleType[role.languageKey]} icon={role.getIcon()} className={classes.chip}/>
    );
}

export default UserRoleComponent;
