import React, {useContext} from 'react';
import {Card, ListItemIcon, ListItemText, Typography} from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import LanguageContext from "../LanguageContext";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { KeyboardArrowRight } from '@mui/icons-material';


function useStyles() {
    return makeStyles(theme => ({
        root: {
            margin: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        container: {
            display: 'flex',
        },
        paper: {
            margin: theme.spacing(1),
        },
        desc: {
            padding_top: theme.spacing(10),
        },
        list: {
            textAlign:"left", 
            marginBottom:"1.5em"
        },
        paragraph: {
            textAlign:"left", 
            marginBottom:"1.5em"
        }
    }));
}

function LegalNotice () {
    const classes = useStyles();
    const local = useContext(LanguageContext);

    return (

        <div className={classes.root}>
            <div className={classes.container}>
                <div title="Description SIA" className={classes.desc}>
                    <Typography variant="h3" component="h2" align="center" gutterBottom>
                        {local.View.LegalNotice.title}
                    </Typography>
                </div>
                <br/>
                <Card variant="outlined" sx={{padding: 2}}>
                    {
                        local.View.LegalNotice.items.map((lnItem) => {
                            return (
                                <div key={`${lnItem.title}`}>
                                    <Typography variant="h4" component="h1" align="left" paragraph key={`1-${lnItem.title}`}>
                                        {lnItem.title}
                                    </Typography>
                                    {
                                        lnItem.text.map((textLine) => {
                                            return (
                                                textLine[0] === "-" ?
                                                    <List className={classes.list} key={`1 - ${textLine}`}>
                                                        <ListItem>
                                                            <ListItemIcon><KeyboardArrowRight/></ListItemIcon>
                                                            <ListItemText>{textLine.slice(2)}</ListItemText>
                                                        </ListItem>
                                                    </List> :
                                                    <div key={`${textLine}`} className={classes.paragraph}>
                                                        <Typography component="p" align="left" paragraph key={`1 - ${textLine}`}>
                                                            {textLine}
                                                        </Typography>
                                                    </div>
                                            );
                                        })

                                    }

                                </div>
                            );
                        })
                    }
                </Card>

            </div>
        </div>
    );
}

export default LegalNotice;
