import React, {useState, useEffect, useContext} from 'react';
import * as PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import { Container, Divider, Grid2 as Grid, Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";

import ProjectCard from "../Component/ProjectCard";
import SeoMetaChanger from "../Component/SeoMetaChanger";
import {getDefaultErrorMessage} from "../Request/requestErrors";
import {getAllProjectByGroup} from "../Request/GitLab/project";
import config from "../config";
import Logo_SIA from "../Assets/Image/sia_color.png";
import LanguageContext from "../LanguageContext";

Projects.propTypes = {
    errorHandler: PropTypes.func.isRequired,
};

function useStyles() {
    return makeStyles(theme => ({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(8),
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
        headerContent: {
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(8, 0, 6),
        }
    }));
}

function Projects ({errorHandler}) {

    const [loaded, setLoaded] = useState(false);
    const [projectList, setProjectList] = useState([]);

    const classes = useStyles();
    const local = useContext(LanguageContext);

    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect((local) => {
        setLoaded(false);
        errorHandler("");
        getAllProjectByGroup(config.SIA_GROUP_ID, currentPage, 12).then( res => {
            setProjectList(res.data);
            setTotalPage(Number(res.pagination["total-page"]));
            setCurrentPage(Number(res.pagination.page));
            setLoaded(true);
        }).catch( err => {
            errorHandler(getDefaultErrorMessage(err, local));
        });
    }, [currentPage, errorHandler]);

    const changePageHandler = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <React.Fragment>
            <SeoMetaChanger lang={local.Lang} description={local.View.Projects.seoDescription} title={local.View.Projects.seoTitle}/>
            <br/>
            <Container maxWidth="md" className={classes.headerContent}>
                <Typography variant="h3" component="h2" align="center" gutterBottom>
                    {local.View.Projects.title}
                </Typography>
                <Typography variant="subtitle1" component="p" align="center" paragraph>
                    {local.View.Projects.subtitle}
                </Typography>
            </Container>
            <Grid container className={classes.root}
                spacing={2} direction="row" justifyContent="center" alignItems="stretch">
                {loaded ? projectList.map((element, index) => {
                    return(<Grid item size={{xs: 10, sm: 6, lg: 4}} key={'project_' + index}>
                        <ProjectCard avatar={(element.avatar_url) ? element.avatar_url : Logo_SIA } 
                            name={element.name} description={(element.description) ? element.description : "Aucune description disponible"}
                            web_url={element.web_url} updated_at={element.last_activity_at} issue_open={element.open_issues_count}
                            issue_mail={element.service_desk_address}/>
                    </Grid>);
                }) : [...Array(12).keys()].map((element) => {
                    return(<Grid item size={{xs: 10, sm: 6, lg: 4}} key={'project_' + element}>
                        <ProjectCard avatar={""} name={""} description={""} issue_open={0}
                            web_url={""} updated_at={""} issue_mail={""} loading />
                    </Grid>);
                })
                }
            </Grid>
            {(totalPage > 1)
                && <React.Fragment>
                    <br/>
                    <Divider/>
                    <br/>
                    <Grid container className={classes.root} justifyContent="center" alignItems="center">
                        <Pagination count={totalPage} page={currentPage} onChange={changePageHandler} variant="outlined" color="primary"/>
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>
    );
}

export default Projects;
